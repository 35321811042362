


export const darkTheme = {
    $foreground: '#FCFAF3',
    $background: '#1A1C2C',
}

export const pageTitles = {
    $purposeImpact: 'Purpose & Impact',
}
